<script setup lang="ts">
const { isSuperadmin, isAdmin } = useUserState()
</script>

<template>
  <v-list>
<!--    <v-divider />
    <v-list-item title="Dashboard" prepend-icon="$mdiCounter" to="/" />-->
    <v-divider />
    <v-list-item title="Briefings" prepend-icon="$mdiListbox" to="/briefings" />
    <v-divider />
    <v-list-item title="Productos" prepend-icon="$mdiDashboard" to="/products" />
    <v-divider />
    <v-list-item title="Recomendaciones" prepend-icon="$mdiAI" to="/insights" />
    <v-divider />
    <v-list-item title="FSBD Adify" prepend-icon="$mdiAIoutline" to="/adify/budget">
<!--      <template v-slot:append>
        <v-badge dot inline color="success" />
      </template>-->
    </v-list-item>
    <v-divider />
    <v-list-item v-if="isSuperadmin || isAdmin" title="Parámetros" prepend-icon="$mdiProgressCheck" to="/parameters" />
    <v-divider v-if="isSuperadmin || isAdmin" />
<!--    <v-list-item v-if="isSuperadmin || isAdmin" title="CC AI" prepend-icon="$mdiChat" to="/ccai" />
    <v-divider v-if="isSuperadmin || isAdmin" />-->
    <v-list-item v-if="isSuperadmin || isAdmin" title="Stock / Ventas" prepend-icon="$mdiWarehouse" to="/stock/report" />
    <v-divider v-if="isSuperadmin || isAdmin" />
    <v-list-item v-if="isSuperadmin || isAdmin" title="Analytics" prepend-icon="$mdiAnalytics" to="/analytics" />
    <v-divider v-if="isSuperadmin || isAdmin" />
  </v-list>
</template>
